@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "mediumFont";
  src: url("/public/fonts/HARMONYOS_SANS_MEDIUM.TTF") format("ttf");
}

@font-face {
  font-family: "boldFont";
  src: url("/public/fonts/HARMONYOS_SANS_BOLD.TTF") format("ttf");
}

@keyframes showHide {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

strong {
  font-family: "boldFont", sans-serif;
  /* strong 元素使用加粗字体 */
}

* {
  scrollbar-width: none;
  /* Firefox */
}

*::-webkit-scrollbar {
  display: none;
  /* Chrome, Edge, Safari */
}

html,
body,
#root {
  font-family: "mediumFont", sans-serif;
  /* 使用引入的外部字体 */
  /* background: #00000085;  */
  /* background: #00000085; */
  background: linear-gradient(to bottom, #430d6a 50%, #17082a);
  height: 100vh;
  width: 100vw;
  max-width: 100vw;
  display: flex;
  color: white;
  /* position: fixed;
  top: 0;
  left: 0; */
  /* min-width: 400px; */
  /* --start-color: #ec3468;
  --end-color: #410483; */
  /* -webkit-overflow-scrolling: touch; */
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.app {
  height: 100%;
  width: 100%;
}

.container {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.loading-circle {
  position: relative;
  margin: auto;
  width: 80px;
  height: 80px;
  --colorA: var(--start-color);
}

.loading-circle::before,
.loading-circle::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  border-top: 3px solid #fff;
  /* filter: drop-shadow(0 0 2px var(--colorA)) drop-shadow(0 0 5px var(--colorA))
    drop-shadow(0 0 10px var(--colorA)) drop-shadow(0 0 20px var(--colorA)); */
  animation: rotate 3s infinite linear;
}

.loading-circle::after {
  --colorA: var(--end-color);
  animation-delay: -1.5s;
}

.step {
  position: fixed;
  top: calc(50% - 12px);
  /* -webkit-box-reflect: below -12px linear-gradient(transparent, rgba(0, 0, 0, 0.5)); */
}

.step span {
  position: relative;
  display: inline-block;
  color: #ffffff;
  font-size: 1em;
  animation: tiktok 2s ease-in-out infinite;
  animation-delay: calc(0.1s * var(--i));
}

.cui-chat {
  background: #1a0d28 !important;
}

.cui-tooltip-inner {
  background-color: #0f0e11;
}

.cui-moreAction {
  color: gray;
}

.cui-empty {
  display: none !important;
}

.cui-suggest {
  background-color: #0f0e11 !important;
}

.cui-suggest-item {
  background-color: #0f0e11 !important;
}

.cui-message-text {
  color: white;
}

.cui-message-base-left .cui-message-base-content {
  background-color: #2b2647 !important;
}

.cui-message-base-right .cui-message-base-content {
  background-color: #6450fd !important;
}

.cui-textarea-input {
  min-height: 36px !important;
}

.cui-modal-content {
  height: auto !important;
}

/* 暂时隐藏菜单切换 */
.cui-conversationList {
  display: none !important;
}

.hire {
  /* background: radial-gradient(
    circle at 78% 15%,
    rgba(54, 4, 81, 0.8),
    rgba(31, 50, 138, 0.8)
  ); */
  height: 100vh;
}

.slide-in-from-right {
  animation: slideInFromRight 0.5s ease-out forwards;
  animation-delay: 0.5s;
}

.slide-in-from-left {
  animation: slideInFromLeft 0.5s ease-out forwards;
  animation-delay: 0.5s;
}

.slide-out-to-left {
  animation: slideOutToLeft 0.5s ease-out forwards;
  animation-delay: 0.5s;
}

.slide-out-to-right {
  animation: slideOutToRight 0.5s ease-out forwards;
  animation-delay: 0.5s;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes tiktok {
  0% {
    transform: translateY(0px);
  }

  20% {
    transform: translateY(-16px);
  }

  40%,
  100% {
    transform: translateY(0px);
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutToLeft {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

@keyframes slideOutToRight {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
}

.border-gradient-custom {
  /* border-image: linear-gradient(to right, #ec3468, #6534ac);
  border-image-slice: 1; */
  background: #000;
  border: 1px solid #ec3468;
  background: #000;
}

.custom-text-color {
  color: #6e6671;
}

.custom-text-size {
  font-size: 5px;
}

.custom-bg-color {
  background: #fcfcfc0a;
}

.green-arrow {
  position: relative;
  padding-left: 15px;
  /* 调整左侧间距 */
}

.green-arrow::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-bottom: 5px solid #3fe51c;
  border-right: 5px solid transparent;
  /* 三角形颜色 */
}

.red-arrow {
  position: relative;
  padding-left: 15px;
  /* 调整左侧间距 */
}

.red-arrow::before {
  content: "";
  position: absolute;
  top: 60%;
  left: 5px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-top: 5px solid #ff6203;
  border-right: 5px solid transparent;
  /* 三角形颜色 */
}

/* CenteredPopup.css */

.popup-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  /* background: #0f0e1171; */
  background: #000000e6;
  z-index: 9999;
  flex-direction: column;
  /* transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;  */
}

.stars {
  /* background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100"%3E%3Cg fill-opacity=".7"%3E%3Ccircle cx="10" cy="10" r=".3" fill="%23f48351" /%3E%3Ccircle cx="40" cy="80" r=".5" fill="%23f48351" /%3E%3Ccircle cx="90" cy="20" r=".7" fill="%23f48351" /%3E%3Ccircle cx="60" cy="60" r=".4" fill="%23c62655" /%3E%3Ccircle cx="80" cy="90" r=".6" fill="%23c62655" /%3E%3Ccircle cx="20" cy="30" r=".8" fill="%23c62655" /%3E%3C/g%3E%3C/svg%3E');
  animation: stars 50s linear infinite;
  backdrop-filter: blur(6px); */

  background-image: url('/public/images/mask.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  flex-direction: column;
}

@keyframes stars {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 100% 100%;
  }
}

.popup {
  /* background: #0f0e11; */
  background: #271336;
  max-width: 768px;
  height: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transform-origin: center;
  opacity: 0;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.mask {
  max-width: 768px;
  width: calc(100vw - 2rem);
  height: 100vh;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transform-origin: center;
  opacity: 0;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.center-popup {
  padding: 15px 30px 30px 30px;
  border-radius: 20px;
  /* min-height: 30vh; */
  max-height: 80vh;
  width: calc(100vw - 2rem);
  border: 2px solid #473455;
}

.bottom-popup {
  position: fixed;
  padding: 15px 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  min-height: 75vh;
  width: 100vw;
  bottom: 0;
  box-sizing: border-box;
}

.popup.visible {
  opacity: 1;
}

.bg-button-up {
  /* background-image: linear-gradient(to bottom left, #da3366, #fe7751); */
  background-image: linear-gradient(to top right, #6040ff, #f734b8);
}

.bg-button-primary {
  background: #fcb833;
  color: #000;
}

button:disabled {
  background-image: none !important;
  background-color: #7d7d7d !important;
  /* 设置禁用状态的背景颜色 */
  cursor: not-allowed;
}

input:focus {
  outline: none !important;
}

textarea:focus {
  outline: none !important;
}

.init-input::placeholder {
  text-align: center;
  color: #fff;
  font-weight: 400;
}

.explore-search::placeholder {
  color: #9a85aa;
}

.init-input {
  color: #fff !important;
  font-weight: 400;
}

.cui-message-editor {
  background: #2b2647;
  opacity: 1;
  padding-bottom: 20px;
  align-items: center;
  border-top: 1px solid #3d2240;
}

.icon-container {
  height: 36px !important;
}

.cui-icon {
  width: 36px !important;
  height: 36px !important;
}

.cui-textarea-sendBtn {
  bottom: 5px !important;
  right: 7px !important;
}

.cui-message-base {
  padding: 8px 15px !important;
}

.cui-message-base-content {
  /* background-color: #725dbd !important; */
  padding: 4px 8px !important;
}

.cui-message-text {
  font-size: 15px !important;
}

.cui-message-base-info {
  margin-bottom: 3px;
}

.cui-textarea-input {
  background: #4b4668 !important;
  color: #fff !important;
}

.cui-textarea-sendBtn .cui-icon {
  max-height: 24px !important;
  ;
  max-width: 24px !important;
  ;
  fill: #b9b0bc !important;
}


.cui-message-editor .icon-container:first-child {
  display: none;
}

.cui-textarea-hasBtn {
  margin-left: 10px;
}

.cui-message-editor .icon-container:nth-child(3) {
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-right: 0px !important
}

.cui-message-editor .icon-container:nth-child(4) {
  margin-left: 0px !important
}

.icon-container {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.rate-table th,
.rate-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.cui-recorder {
  padding: 0 !important;
}


.inner-warning {
  opacity: 1;
  /* 开始时提示框不可见 */
  position: fixed;
  /* 或者 absolute，根据需要 */
  top: 0px;
  /* 根据需要调整 */
  width: 100%;
  background-color: rgba(255, 144, 0, 0.6);
  color: #fff;
  text-shadow: 0px 0px 5px #000;
  /* animation: showHide 30s infinite;   */
  z-index: 9999;
  text-align: center;
}

.board-table th,
.board-table td {
  border: none;
  height: 25px;
  padding: 0;
  text-align: center;
}

.board-table th {
  background-color: #392f2f7b;
}

.board-table tr:nth-child(odd) {
  background-color: #ffffff20;
}

.board-table tr:nth-child(even) {
  background-color: #392f2f7b;
  height: 10px;
}

.cui-message-base-body .cui-message-base-body-action:nth-child(4) {
  display: none;
}

.cui-reply-message-nick .cui-icon svg {
  height: 20px;
}

.btn {
  display: flex;
  box-sizing: content-box;
  border: 2px solid #fff;
  padding: 10px;
  width: 14px;
  height: 14px;
  border-radius: 19px;
}

.custom-tour {
  /* color: black !important; */
  background: linear-gradient(to right, #f48351, #c62655) !important;
  border-radius: 15px !important;
  padding: 5px 10px !important;
  min-height: 40px !important;
  display: flex !important;
  align-items: center !important;
  /* 设置文字颜色为黑色 */
}

.iBMcAJ {
  display: none;
  /* 隐藏默认的 badge 样式 */
}

.fyfPsB {
  opacity: 0.9 !important;
}